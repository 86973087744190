.cyber_security__control_content {
    .status_combobox {
        &.non_applicable {
            .combobox__text {
                color: var(--status--color--non_applicable);
            }
        }

        &.pending {
            .combobox__text {
                color: var(--status--color--pending);
            }
        }

        &.draft {
            .combobox__text {
                color: var(--status--color--draft);
            }
        }

        &.non_complaint {
            .combobox__text {
                color: var(--status--color--non_complaint);
            }
        }

        &.complaint {
            .combobox__text {
                color: var(--status--color--complaint);
            }
        }

        &.enforced_review {
            .combobox__text {
                color: var(--status--color--enforced_review);
            }
        }

        &.under_review {
            .combobox__text {
                color: var(--status--color--under_review);
            }
        }

        &.active {
            .combobox__text {
                color: var(--status--color--active);
            }
        }
    }

    .control_implementation_card {
        padding: 1rem;
        gap: 1rem;
        border: 2px solid var(--color--gray--3);
        border-radius: 1rem;
        grid-template-columns: 1fr auto;
    }
}