:root {
    --font-family--main: 'Montserrat';
    --text-size--1: 10px;
    --text-size--2: 12px;
    --text-size--3: 14px;
    --text-size--4: 18px;
    --text-size--5: 16px;
    --text-size--6: 56px;
    --text-size--7: 18px;
    --text-size--8: 20px;
    --text-size--9: 24px;
    --text-size--10: 24px;

    @media screen and (min-width: $size--laptop) {
        --text-size--4: 18px;
        --text-size--5: 20px;
        --text-size--6: 24px;
        --text-size--7: 28px;
        --text-size--8: 24px;
        --text-size--9: 26px;
    }

    @media screen and (min-width: $size--4k) {
        --text-size--1: 12px;
        --text-size--2: 14px;
        --text-size--3: 16px;
        --text-size--4: 18px;
        --text-size--5: 22px;
        --text-size--6: 28px;
        --text-size--7: 34px;
    }

    font-size: var(--text-size--3);

}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 500;
    position: relative;
    -webkit-tap-highlight-color: transparent;
}

html,
body {
    font-family: var(--font-family--main);
}

html,
body,
#root,
.app_container {
    width: 100%;
    min-height: 100%;
}

.app_container {
    &.is_idle {
        filter: blur(5px);
        overflow: hidden;
        max-height: 100vh;
    }
}

body {
    margin: 0;
    display: flex;
    font-size: 1rem;
    color: var(--text--color--first);
    background-color: rgb(var(--app--bg--color));
    min-height: 100vh;
}

input, button {
    font-family: var(--font-family--main);
}