:root {
    --status--color--non_applicable: #9BAFCB;
    --status--color--pending: #9BAFCB;
    --status--color--draft: #EDB900;
    --status--color--non_complaint: #F4002C;
    --status--color--complaint: #00BF6F;
    --status--color--enforced_review: #F4002C;
    --status--color--under_review: #EDB900;
    --status--color--active: #0E74FF;
}

.non_applicable {
    color: var(--status--color--non_applicable);
}

.pending {
    color: var(--status--color--pending);
}

.draft {
    color: var(--status--color--draft);
}

.non_complaint {
    color: var(--status--color--non_complaint);
}

.complaint {
    color: var(--status--color--complaint);
}

.enforced_review {
    color: var(--status--color--enforced_review);
}

.under_review {
    color: var(--status--color--under_review);
}

.active {
    color: var(--status--color--active);
}