.change_password {
    padding: 0.5em;
    form{
        width: 100%;
        display: grid;
        justify-items: center
    }
    img{
        width: 11.8em;
    }
    .change_password__form {
        background: var(--bg-color--white);
        border-radius: 16px;
    }
}