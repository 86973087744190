.finances__chart_container {
    .control_implementation_card {
        padding: 1rem;
        gap: 1rem;
        border: 2px solid var(--color--gray--3);
        border-radius: 1rem;
        grid-template-columns: 1fr auto;
    }

    .finances__chart_content{
        width: 100%;
        max-width: 900px;
    }
}