.finances__side_menu {
    position: sticky;
    top: 0;
    border-right: solid 2px var(--color--blue--4);
    // height: calc(100vh - 86px);
    max-height: 100vh;

    .section_values__container {
        max-width: 275px;
        overflow-y: auto;
        max-height: 60vh;
    }

    .section_values__option {
        cursor: pointer;
        border-top: 1px solid var(--color--blue--4);

        &:hover {
            background-color: var(--component--option--bg-color--hover);
        }

        &:last-child {
            border-bottom: 1px solid var(--color--blue--4);
        }
    }

    span {
        border-radius: 100%;
        width: 20px;
        height: 20px;

        &.Not-applicable {
            background-color: #9BAFCB;
        }

        &.Active {
            background-color: #0E74FF;
        }

        &.Compliant {
            background-color: #00BF6F;
        }

        &.in_progress {
            background-color: #EDB900;
        }

        &.compromised {
            background-color: #F4002C;
        }
    }

}